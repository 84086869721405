import {Aurelia, inject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthTokenStorage} from "./auth-token-storage";
import {EventAggregator} from 'aurelia-event-aggregator';
import {OAuthClient} from '../api/oauth-client';

const logger = LogManager.getLogger('auth');

@inject(
    Aurelia,
    AuthTokenStorage,
    Router,
    EventAggregator,
    OAuthClient
)
export class AuthService {

    aurelia;
    authTokenStorage;
    router;
    ea;
    oauthClient;

    constructor(aurelia,
                authTokenStorage,
                router,
                ea,
                oauthClient) {
        this.aurelia = aurelia;
        this.authTokenStorage = authTokenStorage;
        this.router = router;
        this.ea = ea;
        this.oauthClient = oauthClient;
    }

    login(token) {
        logger.debug('Login OAuth2 response', token);

        this._updateToken(token);

        this.ea.publish('sio_login_change', {loggedIn: true});

        this.aurelia.setRoot('app');
    }

    logout() {
        this.authTokenStorage.setExpiresAt(null);
        this.authTokenStorage.setAccessToken(null);
        this.authTokenStorage.setRefreshToken(null);

        this.ea.publish('sio_login_change', {loggedIn: false});

        this.aurelia.setRoot('login/login');
    }

    refreshSession() {
        if (!this.authTokenStorage.getRefreshToken() || !this.authTokenStorage.isTokenExpired()) {
            return Promise.resolve();
        }

        return this.oauthClient
            .refreshToken(this.authTokenStorage.getRefreshToken())
            .then(
                (response) => {
                    logger.debug("Refresh OAuth2 access token endpoint response", response);

                    this._updateToken(response);
                }
            )
            .catch(
                (error) => {
                    logger.debug("Unable to refresh access token, will logout completely", error);

                    this.logout();
                }
            )
            ;
    }

    _updateToken(response) {
        let expiresAt = new Date();
        expiresAt.setSeconds(expiresAt.getSeconds() + response.expires_in);

        this.authTokenStorage.setExpiresAt(expiresAt);
        this.authTokenStorage.setAccessToken(response.access_token);
        this.authTokenStorage.setRefreshToken(response.refresh_token);
    }
}
