import {Aurelia, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from './auth-service';
import {AuthTokenStorage} from './auth-token-storage';
import {FlashService} from '../flash/flash-service';

@inject(
    Aurelia,
    Router,
    AuthService,
    AuthTokenStorage,
    FlashService
)
export class AuthInterceptor
{
    constructor(aurelia,
                router,
                authService,
                authTokenStorage,
                flash
    ) {
        this.aurelia = aurelia;
        this.router = router;
        this.authService = authService;
        this.authTokenStorage = authTokenStorage;
        this.flash = flash;
    }

    async request(request)
    {
        await this.authService.refreshSession();

        if (request.headers.has('Authorization')) {
            return request;
        }

        let token = this.authTokenStorage.getAccessToken();

        if (!token) {
            if (request.credentials && request.credentials == 'omit') {
                return request;
            }

            this.aurelia.setRoot('login/login');

            throw new Error('No token set');
        }

        request.headers.set('Authorization', 'Bearer ' + token);

        return request;
    }

    responseError(error)
    {
        if (error.status === 401) {
            //Note: We can refresh session here once we have a possibility to redo the request. Otherwise application will brick.
            //This case should anyway not happen, only if some time related things change on server or tokens are invalidated before their expiry time
            this.authService.logout();
            //this.flash.error('user.session_expired');
            //Todo exclude this case from toastr
        }

        throw error;
    }

}
